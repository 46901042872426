import { template as template_1625918858b84b0a8217ad1d0af2f8b5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_1625918858b84b0a8217ad1d0af2f8b5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
